
@media screen and (min-device-width: 500px){
    .App{
        height: 100vh;
    }
    :root{
        --navbar-height:7vh;
        --category-height:3vh;
        --category-padding:1vh;
        --footer-height:8vh;
        --footer-padding:1vh;
        --body-padding:6vh;
        --body-height:calc( 100vh - var(--body-padding) * 2 - var(--navbar-height) - var(--footer-height) - var(--footer-padding) * 2 );
    }
    
    .navbar{
        height: var(--navbar-height);
        background: #0D262E;
        width: 84vw;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 1;
        padding: 0 8vw;
    }
    
    .category-root{
        position: relative;
        top:var(--navbar-height);
        background: #051A21;
        padding: var(--category-padding) 20px;
        overflow-x: auto;
        display: flex;
        height: var(--category-height);
    }
    
    .body{
        background: #005AA2;
        padding: var(--body-padding) 8vw;
        overflow-y: auto;
        position: relative;
        top:var(--navbar-height);
        min-height: var(--body-height);
    }
    
    .footer{
        background-color: #0D262E;
        text-align: center;
    
        font-family: SourceSansPro-Regular;
        font-size: 1em;
        font-weight: normal;
    
        color: #FFFFFF;
    
        padding: var(--footer-padding) 0;
        height: var(--footer-height);
        
        position: relative;
        top: var(--navbar-height);
    
        z-index: 3;
       
    
    }

}

/* 分辨率低于500px */
@media screen and (max-device-width: 500px){
    .App{
        height: 100vh;
    }
    .APP>span{
        display: none;
    }
    
    
    
    .navbar{
        height: 5vh;
        background: #0D262E;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 1;
    }
    
    .category-root{
        position: relative;
        top:5vh;
        background: #051A21;
        /* padding: 1vh 20px; */
        padding: 2vh 20px 1vh;
        overflow-x: auto;
        display: flex;
        height: 5vh;
    }
    
    .body{
        background: #005AA2;
        padding: 2vh 20px;
        overflow-y: auto;
        position: relative;
        top:5vh;
    }
    
    .footer{
        background-color: #0D262E;
        text-align: center;
    
        font-family: SourceSansPro-Regular;
        font-size: 14px;
        font-weight: normal;
    
        color: #FFFFFF;
    
        padding: 1vh 0;
        /* height: 8vh; */
        height: 10vh;
        
        position: relative;
        top: 5vh;
    
        z-index: 3;
       
    
    }
}