/* pc */
@media screen and (min-device-width: 500px){
    .root-horizontal{
        display: flex;
        flex-wrap: wrap;
    }
    
    .root-vertical{
        height: 50vh;
    }
    
    
    .category-item-horizontal{
        display: flex;
        flex-basis: calc(100% / 5 - 1% );
        max-width: calc(100% / 5 - 1% );
        background: #FFFFFF;
        border-radius: 5px;
        padding:1% 0;
        align-items: center;
        cursor: pointer;
    }
    .category-item-horizontal:not(:nth-child(5n)){
        margin-right: 1%;
    }
    
    .category-item-horizontal:nth-child(n+6){
        margin-top:1%;
    }
    
    .category-item-vertical{
        display: flex;
        align-items: center;
        background: #FFFFFF;
        border-radius: 5px;
        height: 10%;
        cursor: pointer;
    }
    
    
    .category-item-vertical:nth-child(n+2){
        margin-top:5%;
    }
    
    .strategy{
       margin-left:10%;
       
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
    }
    
    .horizontal-icon{
        width: 10%;
        margin-left: 10%;
    }
    
    .vertical-icon{
        margin-left: 10%;
        height: 100%;
    }
    
    .all-icon{
        margin-left: 12%;
        height: 75%;
        margin-right: 2%;
    }
    
    .active{
        background: #FFAF00;
    }
    
}

/* mobile */
@media screen and (max-device-width: 500px){

    .category-item{
        border-radius: 5.5px;
        background: #045CA3;
       
        display: flex;
        padding: 5px 10px;
        align-items: center;
        justify-content: space-around;
           
        font-family: SourceSansPro-Semibold;
        font-size: 12px;
        font-weight: 600;
        color: #FFF;
    }
    
    .category-item:not(:first-child){
        margin-left: 15px;
    }
    
    .category-item>span{
        margin-left: 10px;
    }
    
    .category-item.active{
        color: #0D262E;
        background: #FEB101;
    }
    
    


}



