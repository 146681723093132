@media screen and (min-device-width: 500px){
    .login{
        display: flex;
        align-items: center;
    }
    
    .login>span{
        font-family: SourceSansPro-Semibold;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-right: 10px;
        color: #FFFFFF;
    }
    
    .login>div:first-of-type{
        background-color: #FFF;
        border-radius: 5px;
    }
}

/* 分辨率低于500px */
@media screen and (max-device-width: 500px){
    .logo{
        position: relative;
        left: 5%;
    }
    
    .line{
        background: #FFF;
        width: 20px;
        height: 3px;
    }
    
    .line:not(:first-child){
        margin-top: 2px;
    }
    
    .lines{
        padding: 8px 6px;
    }
    
    .lines.active{
        border-radius: 4px;
        background: linear-gradient(135deg, #FFB200 0%, #FF7E00 100%);
        
    }
    
    .login{
        display: flex;
        
    }
    
    .login>span{
        font-family: SourceSansPro-Semibold;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-right: 10px;
        color: #FFFFFF;
    }
    
    .login>div{
        background-color: #FFF;
        border-radius: 5px;
    }
}