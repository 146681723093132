/* pc */
@media screen and (min-device-width: 500px){
    
.login-content{
    position: fixed;
    z-index: 4;
    height: 100vh;
    width: 100vw;
    top:0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.5);
}



.login-content-close{
    text-align: end;
    width: 62%;
    padding: 0 1%;
    margin-bottom: 1%;
}

.login-content>.login-content-content{
    border-radius: 32px;
    background: #0D262E;
    padding: 1%;
    text-align: center;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    position: relative;
}

.login-content-content>*{
    margin-bottom: 15px;
}

.login-content>.login-content-content>p{
    font-family: SourceSansPro-Black;
    font-size: 2em;
    font-weight: 900;
    text-align: center;
    color: #FFFFFF;
    margin: 0;
}

.login-content>.login-content-content>.login-content-confirm{
    border-radius: 8px;
    background: linear-gradient(99deg, #FFB200 0%, #FF7E00 100%);
    padding: 10px;

    font-family: SourceSansPro-Semibold;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;
}

.login-content-input{
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #ECECEC;

    font-family: SourceSansPro-Light;
    font-size: 1em;
    font-weight: 300;
    color: #FFFFFF;

    /* padding: 10px; */
    padding: 10px 10px 10px 40px;
}

.login-content-input:focus{
    outline:none;
}

.login-content-input::placeholder{
    color: #FFFFFF;
}

.label{
    font-family: SourceSansPro-Light;
    font-size: 1em;
    font-weight: 300;
    color: #FFFFFF;
    text-align: start;
}
.label:first-child{
    margin-top: 20px;
}

.gameplus{
    font-family: SourceSansPro-Light;
    font-size: 1.5em;
    font-weight: 300;
    text-align: center;
    color: #FFFFFF;
}

.signup{
    font-family: SourceSansPro-Bold;
    font-size: 1.5em;
    font-weight: bold;
    line-height: 22px;
    text-align: center;
    color: #FFFFFF;
    text-decoration: underline;
}

.error{
    color:red;
    font-weight: bold;
    font-size:1.2em;
}
}

/* mobile */
@media screen and (max-device-width: 500px){
    .login-content{
        position: fixed;
        z-index: 4;
        height: 100vh;
        width: 100vw;
        top:0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, 0.5);
    }
    
    
    
    .login-content-close{
        text-align: end;
        width: 90%;
    }
    
    .login-content>.login-content-content{
        border-radius: 32px;
        background: #0D262E;
        padding: 20px;
        text-align: center;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        position: relative;
    }
    
    .login-content-content>*{
        margin-bottom: 15px;
    }
    
    .login-content>.login-content-content>p{
        font-family: SourceSansPro-Black;
        font-size: 2em;
        font-weight: 900;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
    }
    
    .login-content>.login-content-content>.login-content-confirm{
        border-radius: 8px;
        background: linear-gradient(99deg, #FFB200 0%, #FF7E00 100%);
        padding: 10px;
    
        font-family: SourceSansPro-Semibold;
        font-size: 1.5em;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
    }
    
    .login-content-input{
        border-radius: 8px;
        background: rgba(255, 255, 255, 0.15);
        border: 1px solid #ECECEC;
    
        font-family: SourceSansPro-Light;
        font-size: 1em;
        font-weight: 300;
        color: #FFFFFF;
    
        padding: 10px 10px 10px 40px;
    }
    
    .login-content-input:focus{
        outline:none;
    }
    
    .login-content-input::placeholder{
        color: #FFFFFF;
    }
    
    .label{
        font-family: SourceSansPro-Light;
        font-size: 1em;
        font-weight: 300;
        color: #FFFFFF;
        text-align: start;
    }
    .label:first-child{
        margin-top: 20px;
    }
    
    .gameplus{
        font-family: SourceSansPro-Light;
        font-size: 1.5em;
        font-weight: 300;
        text-align: center;
        color: #FFFFFF;
    }
    
    .signup{
        font-family: SourceSansPro-Bold;
        font-size: 1.5em;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
        text-decoration: underline;
    }
    
    .error{
        color:red;
        font-weight: bold;
        font-size:1.2em;
    }
}
