/* pc */
@media screen and (min-device-width: 500px){
  .content{
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1vh 10px;
    overflow-y: auto;
  }
}

/* mobile */
@media screen and (max-device-width: 500px){
  .content{
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    padding: 1vh 10px;
    height: 79vh;
    overflow-y: auto;
  }
}

