/* pc */
@media screen and (min-device-width: 500px){
    
.detail-content{
    background: #0D262E;
    border-radius: 24px;
    padding: 20px;
}
.detail-content>div:first-child{
    display: flex;
}


.detail-content-images{
    overflow-x: auto;
    white-space: nowrap;
    margin-top:1%;
}
.detail-content-images::-webkit-scrollbar {
    /*滚动条整体样式*/
    height: 10px;
}
.detail-content-images::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    background   : #535353;
}
.detail-content-images::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background   : #ededed;
}

.detail-content-images>img:nth-child(n+2){
    margin-left: 3%;
}

.detail-content-images>div{
    width: 300px;
    height: 100px;
    border: 1px solid red;
    display: inline;
}

.left{
    width: 30%;
}
.left>img{
    width: 80%;
}
.right{
    width:70%;
}


.detail-content-title{
    font-family: SourceSansPro-Semibold;
    font-size: 2em;
    font-weight: 600;
    color: #FFFFFF;
    margin:0;
    margin-bottom: 10px;

}
.detail-content-category{
    font-family: SourceSansPro-Regular;
    font-size: 1.2em;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.5;
}

.detail-content-action{
    background: #FFC46E;
    border-radius: 5px;
    padding: 1% 3%;
    border: 0;
    margin-left: 1%;

    font-family: SourceSansPro-Semibold;
    font-size: 1.2em;
    font-weight: 600;
    text-align: center;
    color: #3C3C3C;
}

.info{
    display: flex;
    align-items: center;
}

.score{
    display: flex;
    margin-left: 5%;
}
.score>div{
    width: calc(25px * 5);
    height: 18px;
}
.score>span{
    font-family: SourceSansPro-Bold;
    font-size: 1.2em;
    font-weight: bold;
    color: #FFFFFF;
}
.score>div>div{
    flex:auto;
}

.detail-content-play-now{
    background: linear-gradient(97deg, #FFB200 0%, #FF7E00 99%);
    border-radius: 8px;
    border: 0;

    font-family: SourceSansPro-Semibold;
    font-size: 2em;
    font-weight: 600;
    color: #FFFFFF;

    padding: 1% 3%;
    display: inline-block;
}


.detail-content-desc{
    font-family: SourceSansPro-Regular;
    font-size: 1.2em;
    font-weight: normal;
    color: #FFFFFF;
    opacity: 0.5;
}

.screenshot{
    height: 100px;
}

}

/* mobile */
@media screen and (max-device-width: 500px){
    .detail-content{
        background: #0D262E;
        border-radius: 24px;
        padding: 20px;
    }
    .detail-content>div:first-child{
        display: flex;
        height: 15vh;
        justify-content: space-between;
    }
    
    .detail-content>div:first-child img{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    
    .right{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    .detail-content>div>div:last-child{
        width: 40vw;
    }
    
    .detail-content-title{
        font-family: SourceSansPro-Semibold;
        font-size: 2em;
        font-weight: 600;
        color: #FFFFFF;
        margin:0;
        margin-bottom: 10px;
    
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .detail-content-category{
        font-family: SourceSansPro-Regular;
        font-size: 1.2em;
        font-weight: normal;
        color: #FFFFFF;
        opacity: 0.5;
    }
    
    .detail-content-action{
        background: #FFC46E;
        border-radius: 5px;
        padding: 5px 10px;
        border: 0;
        margin-left: 2px;
    
        font-family: SourceSansPro-Semibold;
        font-size: 1.2em;
        font-weight: 600;
        text-align: center;
        color: #3C3C3C;
    }
    
    .score{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top:10px;
        justify-content:space-between;
    }
    .score>div{
        width: calc(25px * 5);
        height: 18px;
    }
    .score>span{
        font-family: SourceSansPro-Bold;
        font-size: 1.2em;
        font-weight: bold;
        color: #FFFFFF;
    }
    .score>div>div{
        flex:auto;
    }
    
    .detail-content-play-now{
        width: 100%;
        display: inline-block;
        text-align: center;
        background: linear-gradient(97deg, #FFB200 0%, #FF7E00 99%);
        border-radius: 8px;
        border: 0;
    
        font-family: SourceSansPro-Semibold;
        font-size: 2em;
        font-weight: 600;
        text-align: center;
        color: #FFFFFF;
    
        margin-top:20px;
        padding: 5px 0;
    }
    
    
    .detail-content-desc{
        font-family: SourceSansPro-Regular;
        font-size: 1.2em;
        font-weight: normal;
        color: #FFFFFF;
        opacity: 0.5;
    }
    
    .detail-content-images{
        overflow-x: auto;
        /* white-space:no */
    }
}
