
/* pc */
@media screen and (min-device-width: 500px){
    .root{
        margin-left: auto;
        margin-right: auto;
    }
    .indicators{
        display: flex;
        justify-content: center;
    }
    .indicators>div{
        margin:0 5px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        opacity: 0.5;
        background: #FFFFFF;
    }
    .indicators>div.active{
        opacity: unset;
    }
    
    .content {
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
      }
    
      .content>img{
        width: 100%;
        max-height: 100%;
      }
}
/* mobile */
@media screen and (max-device-width: 500px){
    .indicators{
        display: flex;
        justify-content: center;
    }
    .indicators>div{
        margin:0 5px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        opacity: 0.5;
        background: #FFFFFF;
    }
    .indicators>div.active{
        opacity: unset;
    }
    
    .content {
        height: 20vh;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        user-select: none;
      }
}