/* pc */
@media screen and (min-device-width: 500px){
  @import-normalize;

  body{
    margin:0;
  }
  button{
    border: 0;
  }
}

/* mobile */
@media screen and (max-device-width: 500px){
  body {
    margin: 0;
  }
}


