/* pc */
@media screen and (min-device-width: 500px){
    
.root{
    background-color: rgba(0, 0, 0, 0.15);
    width: calc(100% / 5 - 1% );
    padding: 0px 0px 8px;
}
.root:not(:nth-child(5n)){
 margin-right: 1%;
}
.root:nth-child(n+6){
    margin-top:1%;
}

.thumbnail{
    width: 100%;
    /* height: 50%; */
}

.thumbnail>img{
    width: 100%;
    /* max-width: 100%; */
    max-height: 100%;
}

.content{
    height: 30%;
}

.title{
    font-family: SourceSansPro-Semibold;
    font-size: 1.5em;
    font-weight: 600;
    color: #FFFFFF;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin: 0 5%;
}

.description{
    font-family: SourceSansPro-Regular;
    /* font-size: 1.2em; */
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    /* height: 20%; */
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 5%;
    height: 60px;
    overflow-y: auto;
}
.divider{
    height: 1px;
    width: 100%;
    background-color: rgba(216, 216, 216, 0.1);
}
.play{
    /* height: 20%; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 5%;
}
.play>button{
    border-radius: 4px;
    background: linear-gradient(104deg, #FFB200 0%, #FF7E00 100%);
    width: 100%;
    padding: 2%;

    font-family: SourceSansPro-Semibold;
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

}
}

/* mobile */
@media screen and (max-device-width: 500px){
    

.root{
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.15);
    padding: 16px;
    display: flex;
    align-items: center;
}

.root:not(:first-child){
    margin-top: 5px;
}

.action>div{
    font-family: SourceSansPro-SemiBold;
    font-size: 16px;
    font-weight: normal;
    color: #FFFFFF;
}

.action{
    width: calc(100% - 30% - 64px);
    padding: 0 2%;
}

.action>div{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.action>button{
    background: #FFC46E;
    border: 0;
    padding: 6px 10px;
    border-radius: 5px;

    font-family: SourceSansPro-Semibold;
    font-size:1em;
    font-weight: 600;
    color: #3F3E3C;

    margin-top: 5px;
}

.play{
    padding:8px 2%;
    width: 28%;
    border-radius: 4px;
    opacity: 1;
    background: linear-gradient(106deg, #FFB200 0%, #FF7E00 100%);
    border: 0;
    height: 60%;

    font-family: SourceSansPro-Semibold;
    font-size: 1em;
    font-weight: 600;
    text-align: center;
    color: #FFFFFF;

    white-space:nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.img{
    border-radius: 10px;
    width: 64px;
}
}
