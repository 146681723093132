/* pc */
@media screen and (min-device-width: 500px){
  
.item{
  display: flex;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}



.img{
  width: 100%;
  max-height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.left {
max-width: 40%;
}

.right {
width: 58%;  
padding: 2% 2%;
}

.title{
  font-family: SourceSansPro-Semibold;
  font-size: 2em;
  font-weight: 600;
  color: #FFFFFF;

  height: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content{
  height: 70%;

  font-family: SourceSansPro-Regular;
  font-size: 1.5em;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);

  
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分省略号 */
  word-break: break-all;  /* break-all(允许在单词内换行。) */  
  display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-line-clamp: 4; /* 显示的行数 */
  /* line-height: 150%; */
  height: 60%;
}

}

/* mobile */
@media screen and (max-device-width: 500px){
  .more-news-root{
    display: flex;
    flex-wrap: wrap;
}

.more-news-root>div:nth-child(3n+2){
    flex:1 1 auto;
}
.more-news-root>div:nth-child(3n+1),.more-news-root>div:nth-child(3n+3){
    flex:0 1 45%;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.25);
    margin-bottom: 10px;
}

.more-news-root div>img{
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.more-news-root div>p{
    font-family: SourceSansPro-Regular;
    font-size: 18px;
    color: #FFFFFF;
    padding: 0;
    
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    -webkit-box-orient: vertical;
    padding-left: 10px;
}
.news-title{
    font-weight: 600;
    -webkit-line-clamp: 2;
}
.news-content{
    font-weight: normal;
    opacity: 0.5;
    -webkit-line-clamp: 4;
}
}


