.root{
    display: flex;
}
.left{
    width: 70%;
}
.right{
    width: 35%;
    margin-left: 5%;
    padding: 0 3%;
}