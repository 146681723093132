/* pc */
@media screen and (min-device-width: 500px){

    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
    }
    .title>span{
        font-family: SourceSansPro-Semibold;
        font-size: 1.8em;
        font-weight: 600;
        color: #FFFFFF;
    }
    .title>button{
        font-family: SourceSansPro-Semibold;
        font-size: 1em;
        font-weight: 600;
        color: #0D262E;
        padding: 5px 10px;
        width: 10vw;
    }
}

/* mobile */
@media screen and (max-device-width: 500px){
    .title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
    }
    .title>span{
        font-family: SourceSansPro-Semibold;
        font-size: 1.2em;
        font-weight: 600;
        color: #FFFFFF;
    }
    .title>button{
        font-family: SourceSansPro-Semibold;
        font-size: 1em;
        font-weight: 600;
        color: #0D262E;
        padding: 5px 10px;
        width: 30vw;
    }
    
}

