
/* pc */
@media screen and (min-device-width: 500px){
    .root{
        display: flex;
        flex: wrap;
    }
    .content{
        background-color: rgba(0, 0, 0, 0.15);
        max-width: calc(100% / 5 - 1% );
    }
    
    .content:not(:nth-child(5n)){
        margin-right: 1%;
    }
    .content:nth-child(n+6){
        margin-top:1%;
    }
    
    .pic_url{
        width: 100%;
        height: 50%;
    }
    
    .title{
        font-family: SourceSansPro-Semibold;
        font-size: 1.5em;
        font-weight: 600;
        color: #FFFFFF;
        height: 10%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 0 5%;
    }
    
    .abstract{
        font-family: SourceSansPro-Regular;
        font-size: 1.2em;
        font-weight: normal;
        color: rgba(255, 255, 255, 0.5);
        height: 30%;
        padding: 0 5%;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* mobile */
@media screen and (max-device-width: 500px){
    .news-root{
        border-radius: 6px;
        background: rgba(0, 0, 0, 0.15);
        padding: 16px;
        display: flex;
    }
    
    .news-root:not(:first-child){
        margin-top: 5px;
    }
    .news-root>:last-child{
       width: calc(100% - 64px  - 10px);
    }
    .new-content{
        padding-left: 10px;
    }
    .new-content>div{
        font-family: SourceSansPro-Semibold;
        font-size: 14px;
        font-weight: 600;
        color: #FFFFFF;
    }
    .new-content>p{
        font-family: SourceSansPro-Regular;
        font-size: 11px;
        font-weight: normal;
        color: #FFFFFF;
        opacity: 0.5;
    }
}

