/* 滚动条 */
::-webkit-scrollbar{
    width: 1vh;
}
:root{
    --margin-top:25%;
    --width:65%;
}

.root{
    display: flex;
    height: 100vh;
}
.left{
    flex-basis: 20%;
    background: #0D262E;
}

.logo{
    width: var(--width);
    padding: 0 calc((100% - var(--width)) / 2);
    margin-top:var(--margin-top)
}

.game-type{
    width: var(--width);

    font-family: SourceSansPro-Semibold;
    font-size: 2em;
    font-weight: 600;
    
    color: #FFFFFF;
    padding: 0 calc((100% - var(--width)) / 2);

    margin-top: var(--margin-top);

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    text-align: center;
}


.strategy-content {
    margin-top: 10%;
    width: calc(var(--width) - 10%);
    padding: 0 calc((100% - (var(--width) - 10%)) / 2);
  }


.right{
    flex-basis: 80%;
    background: #005AA2;
    overflow: auto;
}

.content{
    display: flex;
    flex-wrap: wrap;
    margin-top:8%;
    padding:0 5%;
    margin-bottom: 10%;
}

.ooo{
    position: absolute;
    display: none;

    background: linear-gradient(154deg, rgba(255,255,255,0.00) 1%, rgba(255,255,255,0.10) 100%);
    opacity: 0.5;
    margin-left: 20%;
    width: 80%;
    height: 100%;
}
